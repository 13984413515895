import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Res from "../components/Res";
import Navbar from "../components/Navbar";
import Option from "../components/Option";
import Coffer from "../components/Coffer";
import Animities from "../components/Animities";
import Landmarks from "../components/Landmarks";

const Home = () => {
 
  return (
    <>
      <style dangerouslySetInnerHTML={{ __html: "body{background: #c3b9b9" }} />
      <Navbar />
      {/* Video Section */}
      <header className="hero-section" id="section_1">
        <div className="section-overlay" />
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mt-5 ">
              <h5 className="text-white mt-3">HELLO RESIDENT
              </h5>
              <h3 className="text-white mt-4 text-uppercase"> WELCOME TO Supertech Capetown </h3>
              <p className="text-white">All Rights Reserved For Supertech Capetown  Residents [Owners &amp;
                Tenants], <br />
                Offers and discounts are valid only for society residents.</p>
              <Link to="/login" className="btn custom-btn smoothscroll mb-5"> JOIN US
                NOW!</Link>
            </div>
          </div>
        </div>
        <div className="video-wrap">
          <video autoPlay loop muted className="custom-video">
            <source src="assets/video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </header>

      {/* Access Card */}
      <section className="about-section " id="section_2">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 col-sm-12 mt-5 mb-5">
              <div className="team-style1 text-center">
                <img src="assets/images/a.jpg" className="border-radius-5 rounded " alt="..." id="imgcard" />
                <div className="team-info">
                  <Link to="/login" className="btn btn-outline-danger mt-2" id="btn4"> <b>
                    Apply Access Card </b></Link>
                  <p className="text-white mt-1"> Security is important for us </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12 mt-5 mb-5">
              <div className="team-style1 text-center">
                <img src="assets/images/p.jpg" className="border-radius-5 rounded" alt="..." id="imgcard" />
                <div className="team-info">
                  <Link to="/login" className="btn btn-outline-danger mt-2" id="btn4">
                    <b>Apply RFID TAG [Boom Barrier] </b></Link>
                  <p className="text-white mt-1">Also, Request For Parking Sticker 
                   </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12 mt-5 mb-5">
              <div className="team-style1 text-center">
                <img src="assets/images/pet.jpg" className="border-radius-5 rounded " alt="..." id="imgcard" />
                <div className="team-info">
                  < Link to="/Login" className="btn btn-outline-danger mt-2" id="btn4">
                    <b>Register Now</b></Link>
                  <p className="text-white mt-1 ">We are Pet friendly, Your pet is Welcome
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12 mt-5 mb-5">
              <div className="team-style1 text-center">
                <img src="assets/images/hall2.png" className="border-radius-5 rounded " alt="..." id="imgcard" />
                <div className="team-info">
                  <a href data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn btn-outline-danger mt-2" id="btn4"> <b>Book Your Place</b></a>
                  <p className="text-white mt-1 ">Resources & Amenities
                    Booking
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* popup form */}
        <Option />
      {/* Lifestyle Amenities */}
        <Animities />
      {/* Society Rules & Regulations */}
      <section>
        <div className="container mt-4">
          <div className="row">
            <div className="col-12 ">
              <h2 className="radiant mb-3"> Society Rules &amp; Regulations For Residents</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12 mt-4">
              <div className="box p-3 ">
                <Link to="/apartments-pet-rules"> <span><i className="bi bi-journal-text text-danger" /> &nbsp;
                </span>Apartments Pet Rules – Rights &amp;
                  Responsibility of Pet... <span className="text-danger">
                    Read More</span></Link>
                <hr />
                <Link to="/society-bylaws-for-internal-repairs"> <span> <i className="bi bi-journal-text text-danger" /> &nbsp;</span>
                  Society Bylaws for Internal
                  Repairs: A Comprehen...<span className="text-danger"> Read
                    More</span></Link>
                <hr />
                <Link to="/housing-society-rules&regulations-for-tenants"> <span>
                  <i className="bi bi-journal-text text-danger" />  &nbsp;</span>
                  Society
                  Rules &amp; Regulations &amp;
                  For Tenants...<span className="text-danger"> Read
                    More</span></Link>
                <hr />
                <Link to="/reserve-parking-rules" > <span><i className="bi bi-journal-text text-danger" />
                  &nbsp;</span>Reserve
                  Parking in Society:
                  Understanding Rules &amp; Law...<span className="text-danger">
                    Read More</span></Link>
                <hr />
                <Link to="/election-procedure-for-housing-societies"> <span> <i className="bi bi-journal-text text-danger" /> &nbsp;</span>
                  Election Procedure For &amp; Society...<span className="text-danger"> Read
                    More</span></Link>
                <hr />
                <Link to="/duties-of-residents"> <span> <i className="bi bi-journal-text text-danger" />
                  &nbsp;</span>Duties
                  Residents in Society...<span className="text-danger"> Read
                    More</span></Link>
                <hr />
                <Link to="Login" className="text-danger"> See all &nbsp;<i className="bi bi-arrow-right" /></Link>
              </div>
            </div>
            <div className="col-lg-6 col-12 mt-4 ">
              <div className="row">
                <div className="col-12">
                  <div className="box p-4" style={{ backgroundColor: '#d3d3d3' }}>
                    <h2 className="post-title">
                      <Link to="/gym-rules&regulations" className="article-link mb-1" >
                        GYM RULES &amp; REGULATIONS
                      </Link>
                    </h2>
                    <div className="summary" id="su">
                      All gym users must be properly attired in standard workout clothes i.e. Tank
                      tops, T-shirts, shorts, leotards with running or
                      cross training shoes (not use outside the gym) while in exercise area...
                    </div>
                    <Link to="/gym-rules&regulations" className="btn btn-danger mt-2"> Expolore Now</Link>
                  </div>
                </div>
                <div className="col-12 mt-4">
                  <div className="box p-4" style={{ backgroundColor: '#d3d3d3' }}>
                    <h2 className="post-title ">
                      <Link to="/swimming-pool-rules&regulations" className="article-link mb-1">
                        SWIMMING POOL RULES &amp; REGULATIONS
                      </Link>
                    </h2>
                    <div className="summary" id="su">
                      Swimming Pool Rules and Regulations in a Residential Society Come summer, a
                      society’s swimming pool is an
                      entertaining and vibrant hangout for children as well as adults...
                    </div>
                    <Link to="/swimming-pool-rules&regulations" className="btn btn-danger mt-2">Expolore Now</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Our socity events */}
      <section className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              <h2 className="radiant text-center">Our Society Events...</h2>
            </div>
          </div>
          <div className="row mt-4 p-2" id="commupd">

          <div className="col-lg-4 popular-post box " style={{ backgroundColor: '#d3d3d3' }}>
              <div className="row mt-2 mb-4">
                <h6 className="mt-4 mb-4"> COMMUNICATION UPDATE</h6>
                <hr />
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <Link to="/login" ><span> <i className="bi bi-journal-text text-danger" />
                        &nbsp;</span> Access your common society documents, Registers, MOM/AGM
                        Report,
                        etc.</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <hr />
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <Link to="/login" ><span> <i className="bi bi-journal-text text-danger" />&nbsp;
                        Society Billing and Accounting - Water bill, Electricity
                        Bill,
                        etc.</span></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <hr />
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <Link to="/login" > <span> <i className="bi bi-journal-text text-danger" />&nbsp;
                        NOC, Reports &amp; Certificate [ fire, STP, Lift ]</span></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <hr />
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <Link to="/login" > <span> <i className="bi bi-journal-text text-danger" />
                        &nbsp; Procurement &amp; Purchase Details / Order</span></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <hr />
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <Link to="/login" ><span> <i className="bi bi-journal-text text-danger" />&nbsp;
                        Pet dog Rules &amp; Registration</span></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <hr />
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <Link to="/login" className="article-link"> <span> <i className="bi bi-journal-text text-danger" /> &nbsp; Society Rules &amp;
                        Regulations &amp; For
                        residents</span></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5 mb-5">
                <div className="col-12">
                  <Link to="/login" className="btn btn-danger">ALL UPCOMING MEETINGS</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-8 card"  style={{ backgroundColor:  '#fbfdfe'}}>
              <article>
                <div className="line-item hf-item-odd clearfix">
                  <div className="content-image">
                    <a className="image-link article-link" href>
                      <img className="rounded box" src="assets/images/15august.avif" alt="img" id="imgthum" />
                      <span className="overlay article-overlay" />
                    </a>
                  </div>
                  <div className="hf-info">
                    <div className="hf-category">
                      <Link to='/login' className="text-danger">AUGUST
                      </Link>
                    </div>
                    <h2 className="post-title">
                      <Link to='/login' className="article-link" >
                        Up Coming Event Independence Day
                        <span className="overlay article-overlay" />
                      </Link>
                    </h2>
                    <div className="summary">
                      Independence Day is celebrated annually on 15 August as a public holiday in
                      India
                      commemorating the nation's....
                    </div>
                    <Link to='/login' className="btn btn-danger mt-2">Read More</Link>
                  </div>
                </div>
              </article>
              <article>
                <div className="line-item hf-item-odd clearfix">
                  <div className="content-image">
                    <Link to='/login' className="image-link article-link" >
                      <img className="rounded box" src="assets/images/holi.avif" alt="img" id="imgthum" />
                      <span className="overlay article-overlay" />
                    </Link>
                  </div>
                  <div className="hf-info">
                    <div className="hf-category">
                      <Link to='/login' className="text-danger"> MARCH</Link>
                    </div>
                    <h2 className="post-title">
                      <Link to='/login' className="article-link">
                        Holi Festival 2024 :
                        <span className="overlay article-overlay" />
                      </Link>
                    </h2>
                    <div className="summary">
                      Holi is a popular and significant Hindu festival celebrated as the Festival of
                      Colours,
                      Love, and Spring. It celebrates...
                    </div>
                    <Link to='/login' className="btn btn-danger mt-2">Read More</Link>
                  </div>
                </div>
              </article>
              <article>
                <div className="line-item hf-item-odd clearfix">
                  <div className="content-image">
                    <a className="image-link article-link" href>
                      <img className="rounded box" src="assets/images/newyear.jpeg" alt="img" id="imgthum" />
                      <span className="overlay article-overlay" />
                    </a>
                  </div>
                  <div className="hf-info">
                    <div className="hf-category">
                      <Link to='/login' className="text-danger">JANUARY</Link>
                    </div>
                    <h2 className="post-title">
                      <Link to='/login' className="article-link" >
                        New Year Party 2024 :
                        <span className="overlay article-overlay" />
                      </Link>
                    </h2>
                    <div className="summary">
                      The New Year is the time or day at which a new calendar year begins and the
                      calendar's
                      year count increments by one....
                    </div>
                    <Link to='/login' className="btn btn-danger mt-2">Read More</Link>
                  </div>
                </div>
              </article>
              <article>
                <div className="line-item hf-item-odd clearfix">
                  <div className="content-image">
                    <a className="image-link article-link" href >
                      <img className="rounded box" src="assets/images/crimas.avif" alt="img" id="imgthum" />
                      <span className="overlay article-overlay" />
                    </a>
                  </div>
                  <div className="hf-info">
                    <div className="hf-category">
                      <Link to='/login' className="text-danger">DECEMBER</Link>
                    </div>
                    <h2 className="post-title">
                      <Link to='/login' className="article-link">
                        Merry Christmas Celebration
                        <span className="overlay article-overlay" />
                      </Link>
                    </h2>
                    <div className="summary">
                      Merry Christmas" is a spoken or written greeting traditionally used on or before
                      the
                      Christmas holiday....
                    </div>
                    <Link to='/login' className="btn btn-danger mt-2">Read More</Link>
                  </div>
                </div>
              </article>
            </div>
          </div>
     
     

       </div>
        
      </section>

      {/* Landmarks Near Supertech Capetown  */}
         <Landmarks />
         
     {/* recharge */}
      <section>
       <div className="container">
          <div className="row">
           <div className="col-lg-4 col-12 text-center mt-5 card" style={{ backgroundColor: '#dcf0fa' }}>
              <div className="containerr ms-4">
                <img src="assets/images/medcine.jpeg" alt="Avatar" className="medimg " />
                <div className="middle2">
                  <a href="https://janaushadhi.gov.in/KendraDetails.aspx" target="_blank" rel="noopener noreferrer" className="text2 btn btn-danger">Know More</a>
                </div>
              </div>
           

            </div>

              
        <div className="col-lg-7 col-12 mt-5 justify-content-center">
          <div className="row">
            <div className="col-12">
           <h4 className="radiant ms-3 " > Amazing Offers; 
               Recharge & pay Bills,
            Get Upto 15% Cashback! </h4>
             
            </div>

          </div>
       
        <div class="row mt-2" id="Amenities">
            <div class="col-2" id="ic" style={{ backgroundColor: '#dcf0fa' }} >
              <Link to="/login">
              <img src="assets/images/icon/mobilere.png" alt="" width="40" class="mt-2" /> <br />
              Mobile & Broadband </Link>
            </div>
            <div class="col-2" id="ic" style={{ backgroundColor: '#dcf0fa' }} >  <Link to="/login">
              <img src="assets/images/icon/gas.png" alt="" width="40" class="mt-2 mb-2" />
              <br /> Gas Pipeline </Link>
            </div>
            <div class="col-2" id="ic" style={{ backgroundColor: '#dcf0fa' }} >
            <Link to="/login">
              <img src="assets/images/icon/lic.png" alt="" width="40" class="mt-2 mb-2" /> <br />
              LIC/Insurance </Link> 
            </div> 
            <div class="col-2" id="ic" style={{ backgroundColor: '#dcf0fa' }} >
            <Link to="/login"><img src="assets/images/icon/card.png" alt="" width="40" class="mt-2 mb-2 " />
              <br /> Credit Card </Link> 
            </div> 
            <div class="col-2" id="ic" style={{ backgroundColor: '#dcf0fa' }}  >
            <Link to="/login">  <img src="assets/images/icon/loan.png" alt="" width="40" class=" mt-2 mb-2" />
              <br /> Loan EMI </Link>
            </div>
            <div class="col-2" id="ic" style={{ backgroundColor: '#dcf0fa' }} >
            <Link to="/login">  <img src="assets/images/icon/resto.png" alt="" width="40" class=" mt-2 mb-2" />
              <br />Restaurants</Link>
            </div>

            <div class="col-2" id="ic" style={{ backgroundColor: '#dcf0fa' }} >
            <Link to="/login">  <img src="assets/images/icon/edu.png" alt="" width="40" class=" mt-2 mb-2" />
              <br />Education </Link>
            </div> 
            <div class="col-2" id="ic" style={{ backgroundColor: '#dcf0fa' }} >
            <Link to="/login">  <img src="assets/images/icon/travler.png" alt="" width="40" class=" mt-2 mb-2" />
              <br />Travel </Link>
            </div>
            <div class="col-2" id="ic" style={{ backgroundColor: '#dcf0fa' }} >
            <Link to="/login">  <img src="assets/images/icon/brand.png" alt="" width="40" class=" mt-2 mb-2" />
              <br />Top Brands </Link>
            </div>
            <div class="col-2" id="ic" style={{ backgroundColor: '#dcf0fa' }} >
            <Link to="/login"> <img src="assets/images/icon/see.png" alt="" width="40" class=" mt-2 mb-2" />
              <br /> See All </Link>
            </div>





          </div>
        
        </div> 
        </div>
        </div>
      </section>
       
       {/* offers */}
       <section className="mt-4">
        <Coffer />
       </section>

      {/*New Commercial Development */}
      
       <Res />
       <Footer />
    </>
  );
}

export default Home;